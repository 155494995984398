#modalTypicalProjectStages {

	.modal__wrapper {
		max-width: 130rem;
	}

	h1 {
		padding-bottom: 1.6rem;
		margin-bottom: 0;
	}

	.intro {
		width: 100%;

		@media only screen and (min-width:990px) {
			max-width: 50%;
		}

		p:first-child {
			@extend %lead-p-format;
		}

	}

	.stages {
		display: flex;
		flex-wrap: wrap;
		border-bottom: 1px solid $grey;
		margin-bottom: 4.8rem;
	}

	.stage {
		padding-bottom: 3.2rem;
		border-bottom: 1px solid $grey;
		margin: 3.2rem 0;

		&:last-child {
			padding-bottom: 0;
			border: 0;
		}

		@media only screen and (min-width:719px) {
			margin: 3.2rem 4.8rem;

			&:last-child {
				padding-bottom: 3.2rem;
				border-bottom: 1px solid $grey;
			}

			&:nth-last-child(-n+2) {
				padding-bottom: 0;
				border: 0;
			}

		}


		@media only screen and (min-width:719px) {
			width: calc(50% - 4.8rem);

			&:nth-child(2n+1) {
				margin-left: 0;
			}

			&:nth-child(2n) {
				margin-right: 0;
			}

		}

		@media only screen and (min-width:990px) {
			width: calc(33.3333% - 6.4rem);

			&:nth-child(2n+1), &:nth-child(2n) {
				margin: 3.2rem 4.8rem;
			}

			&:nth-child(3n+1) {
				width: calc(33.3333% - 6.4rem);
				margin-left: 0;
			}
			&:nth-child(3n) {
				width: calc(33.3333% - 6.4rem);
				margin-right: 0;
			}

		}


		h2 {
			font-size: 4.8rem;
			padding-bottom: 1.6rem;

			span {
				display: block;
				padding-top: 1.6rem;
				font-size: 2.4rem;
				line-height: 1.2em;
				font-family: $base-font-family;
				font-size: 1.8rem;
				font-weight: 400;
			}
		}

	}

	.outro {
		width: 100%;
		margin: 0 auto;
		text-align: left;
	}

}
