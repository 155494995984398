#contact {
	background: $navy;
	margin-top: 12.8rem;
	padding: 12.8rem 1.6rem;
	color: white;

	.inner-wrapper {
		max-width: 130rem;
		overflow: hidden;

		@media only screen and (min-width:900px) {
			display: flex;
		}

		.intro {
			width: 100%;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: space-between;
			color: white;


			@media only screen and (min-width:900px) {
				width: 43.5rem;
				padding-right: 4.8rem;
			}

			hr {
				height: 2px;
				background: white;
				max-width: 14.5rem;
			}

			.follow {
				font-size: 1.3rem;
			}

			.social--twitter, .social--houzz, .follow, hr {
				display: none;
				@media only screen and (min-width:900px) {
					display: block;
				}
			}
		}
	}

	#contactForm {
		background: white;
		border-radius: 5px;
		width: 100%;
		padding-bottom: 1.6rem;

		@media only screen and (min-width:500px) {
			padding: 3.2rem 3.2rem 4.8rem 3.2rem;
		}

		@media only screen and (min-width:900px) {
			flex: 1;
		}
	}

}

.reg-logos {
	margin-bottom: 0;
}

.reg-logo {
	display: inline-block;
	width: 8rem;
	border-radius: 5px;
	vertical-align: bottom;

	&.bordered {
		border: 5px solid white;
		width: 9rem;
	}
}


.cheat-end {
	display: block;
	padding-top: 2.4rem;

	@media only screen and (min-width:900px) {
		display: none;
	}
}
