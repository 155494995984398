@import	"_kit/normalise", "_kit/resets-helpers"; // Standardise elements
@import url('//fonts.googleapis.com/css?family=Dosis:300|Open+Sans:300,400');
// Google Font Reference(s)
$font-open-sans: 'Open Sans', sans-serif;
$font-dosis: 'Dosis', sans-serif;

$brand-color: #ffc65c;
$alt-brand-color: #7d7c7c;
$blue: #82A7BE;
$green: darken($blue, 5%);
$grey: #BEBEBE;
$navy: #2D3E50;
$yellow: #FFF0A5;
$red: #E64C66;

// Our variables
$base-font-family: $font-open-sans; // $font-quicksand;
$base-font-size:	1.5rem;
$base-font-weight:	300;

$base-line-height:	1.8;

$base-text-color:	$navy;
$background-color: 	#f6f6f6;

$wrapper-width:		100rem;
$wrapper-padding:	0 0.8rem;

%default-padding {
	padding: 4.8rem;
}

$positive-interactive: $green;

%link-button-format {
	font-size: 1.2rem;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1.5px;
}

%lead-p-format {
	font-weight: 400;
}

@import 'global';
@import 'lightbox';
@import 'toaster';
@import 'buttons';
@import 'modal';
@import 'modal-project-stages';
@import 'header-nav';
@import 'section';
@import 'section-examples';
@import 'section-contact';
@import 'form';
