.toaster {
  display: none;
  background: #1E824C;
  z-index:9999;
  position:fixed;
  top:0;
  width:100%;
  padding:8px;
  color: white;
  text-align:center;
  -webkit-box-shadow: 0 5px 8px 0 rgba(0,0,0,0.05);
  box-shadow: 0 5px 8px 0 rgba(0,0,0,0.05);
  font-weight: 400;

  .toaster__title {
    display:block;
    font-size:14px;
  }

  .toaster__content {
    font-size:14px;
  }

  &.error {
    background: tomato;
    color: white;
  }

  &.thanks {
    background: #1E824C !important;
    color: white;
  }

  @media only screen and (min-width:800px) {
    padding:16px;

    .toaster__title {
      display:inline;
    }

  }

}
