// You can customize Simplelightbox with the following variables:

$sl-font-family: Arial, Baskerville, monospace !default;
$sl-overlay-background: #fff !default;
$sl-overlay-opacity: 0.8 !default;
$sl-navigation-color: darken($blue, 10%) !default;
$sl-caption-color: #333 !default;
$sl-caption-background: rgba(255, 255, 255, 0.55) !default;

$sl-counter-fontsize: 1.4rem !default;
$sl-caption-fontsize: 1.3rem !default;
$sl-close-fontsize: 5rem !default;

$sl-breakpoint-medium: 568px !default; // 568px, when 1em == 16px
$sl-breakpoint-large:	800px !default;	 // 800px, when 1em == 16px

$sl-arrow-fontsize-small:	3rem !default;
$sl-arrow-fontsize-medium: 4rem !default;
$sl-arrow-fontsize-large:	5rem !default;
$sl-img-border-small:	0 none !default;
$sl-img-border-medium: 0 none !default;
$sl-img-border-large:	0 none !default;
$sl-iframe-border-small:	0 none !default;
$sl-iframe-border-medium: 0 none !default;
$sl-iframe-border-large:	0 none !default;

$add-vendor-prefixes: true !default;

body.hidden-scroll {
	overflow: hidden;
}
.sl-overlay {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: $sl-overlay-background;
	opacity: $sl-overlay-opacity;
	display: none;
	z-index: 1035;
}
.sl-wrapper {
	z-index: 1040;
	button {
		border: 0 none;
		background: transparent;
		font-size: 28px;
		padding: 0;
		cursor: pointer;
		&:hover {
			opacity: 0.7;
		}
	}

	.sl-close {
		display: none;
		position: fixed;
		right: 30px;
		top: 30px;
		z-index: 1060;
		margin-top: -14px;
		margin-right: -14px;
		height: 44px;
		width: 44px;
		line-height: 44px;
		font-family: $sl-font-family;
		color: $sl-navigation-color;
		font-size: $sl-close-fontsize;

		&:focus {
			outline: none;
		}
	}

	.sl-counter {
		display: none;
		position: fixed;
		top: 30px;
		left: 30px;
		z-index: 1060;
		color: $sl-navigation-color;
		font-size: $sl-counter-fontsize;
	}

	.sl-navigation {
		width: 100%;
		display: none;
		button {
			position: fixed;
			top: 50%;
			margin-top: -22px;
			height: 44px;
			width: 22px;
			line-height: 44px;
			text-align: center;
			display: block;
			z-index: 1060;
			font-family: $sl-font-family;
			color: $sl-navigation-color;
			&.sl-next {
				right: 5px;
				font-size: $sl-arrow-fontsize-small;
			}

			&.sl-prev {
				left: 5px;
				font-size: $sl-arrow-fontsize-small;
			}

			&:focus {
				outline: none;
			}

			@media (min-width: $sl-breakpoint-medium) {
				width: 44px;

				&.sl-next {
					right: 10px;
					font-size: $sl-arrow-fontsize-medium;
				}

				&.sl-prev {
					left: 10px;
					font-size: $sl-arrow-fontsize-medium;
				}
			}
			@media (min-width: $sl-breakpoint-large) {
				width: 44px;

				&.sl-next {
					right: 20px;
					font-size: $sl-arrow-fontsize-large;
				}

				&.sl-prev {
					left: 20px;
					font-size: $sl-arrow-fontsize-large;
				}
			}
		}
	}

	.sl-image {
		position: fixed;
		@if $add-vendor-prefixes {
			-ms-touch-action: none;
		}
		touch-action: none;
		z-index: 10000;
		img {
			margin: 0;
			padding: 0;
			display: block;
			border: $sl-img-border-small;
			@media (min-width: $sl-breakpoint-medium) {
				border: $sl-img-border-medium;
			}
			@media (min-width: $sl-breakpoint-large) {
				border: $sl-img-border-large;
			}

		}
		iframe {
			background: #000;
			border: $sl-iframe-border-small;
			@media (min-width: $sl-breakpoint-medium) {
				border: $sl-iframe-border-medium;
			}
			@media (min-width: $sl-breakpoint-large) {
				border: $sl-iframe-border-large;
			}
		}
		.sl-caption {
			display: none;
			padding: 10px;
			color: $sl-caption-color;
			background: $sl-caption-background;
			font-size: $sl-caption-fontsize;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
      line-height: 1.2em;

			&.pos-top {
				bottom: auto;
				top: 0;
			}

			&.pos-outside {
				bottom: auto;
			}
		}

		.sl-download {
			display: none;
			position: absolute;
			bottom: 5px;
			right: 5px;
			color: $sl-navigation-color;
			z-index: 1060;
		}
	}
}

.sl-spinner {
		display: none;
		border: 5px solid #333;
		border-radius: 40px;
		height: 40px;
		left: 50%;
		margin: -20px 0 0 -20px;
		opacity: 0;
		position: fixed;
		top: 50%;
		width: 40px;
		z-index: 1007;
		@if $add-vendor-prefixes {
			-webkit-animation: pulsate 1s ease-out infinite;
			-moz-animation: pulsate 1s ease-out infinite;
			-ms-animation: pulsate 1s ease-out infinite;
			-o-animation: pulsate 1s ease-out infinite;
		}
		animation: pulsate 1s ease-out infinite;
}

.sl-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}

@-webkit-keyframes pulsate{
		0% {
			transform: scale(.1);
			opacity: 0.0;
		}
		50% {
			opacity: 1;
		}
		100% {
			transform: scale(1.2);
			opacity: 0;
		}
}
@keyframes pulsate {
		0% {
			transform: scale(.1);
			opacity: 0.0;
		}
		50% {
			opacity: 1;
		}
		100% {
			transform: scale(1.2);
			opacity: 0;
		}
}
@if $add-vendor-prefixes {
	@-moz-keyframes pulsate{
			0% {
				transform: scale(.1);
				opacity: 0.0;
			}
			50% {
				opacity: 1;
			}
			100% {
				transform: scale(1.2);
				opacity: 0;
			}
	}
	@-o-keyframes pulsate{
			0% {
				transform: scale(.1);
				opacity: 0.0;
			}
			50% {
				opacity: 1;
			}
			100% {
				transform: scale(1.2);
				opacity: 0;
			}
	}
	@-ms-keyframes pulsate{
			0% {
				transform: scale(.1);
				opacity: 0.0;
			}
			50% {
				opacity: 1;
			}
			100% {
				transform: scale(1.2);
				opacity: 0;
			}
	}
}
