.button {
	@extend %link-button-format;
	display: inline-block;
	padding: 1.2rem 2.4rem;
	min-width: 15rem;
	text-align: center;
	background: $positive-interactive;
	color: white;
	transition: all 0.5s;
	border: 1px solid transparent;
	cursor: pointer;
	border-radius: 5px;

	&:hover {
		background: darken($positive-interactive, 10%);
		border-color: darken($positive-interactive, 10%);
	}

	&.icon {
		background-position: 2.3rem center;
		background-repeat: no-repeat;
		padding-left: 3rem;

		&--houzz {
			background-image: url(/assets/houzz.svg);
		}

	}
}
