html,
body {
	padding: 0;
	margin: 0;
	position: relative;
}

html {
	font-size: 62.5%;
}

body {
	font-family: $base-font-family;
	font-weight: $base-font-weight;
	background: $background-color;
	font-size: $base-font-size;
	color: $base-text-color;
	line-height: $base-line-height;
	text-rendering: optimizeLegibility;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
		padding-top: 9.2rem;
}

a {

}

h1,
h2,
h3,
h4 {
	line-height: 1em;
	padding: 0.5em 0 0.35em 0;

	& + p {
		margin-top: 0;
	}
}

a:not(.global-nav__link):not(.button):not(.icon):not(.modal__closer):not(.logo) {
	color: $green;
	transition: all 0.3s;

	&:hover {
		color: darken($green, 10%);
	}
}

h1, h2, h3, h4 {
	padding-top: 0;
	padding-bottom: 0.6em;
	color: $navy;
	font-family: $font-dosis;
	font-weight: 300;
	letter-spacing:1px;
}

h1 {
	border-bottom: 2px solid $blue;
	display: inline-block;
	clear: both;
	font-size: 4.8rem;
	padding-bottom: 1.4rem;
	color: $blue;
	margin-bottom: 1.2rem;

	& + p:not(.simple) {
		@extend %lead-p-format;
	}

}

.global-wrapper {

	.section {

		.inner-wrapper {
			margin: 0 auto;
			width: 100%;
			position: relative;
			z-index: 10;
			text-align: left;
		}

	}

}

.inner-wrapper, .modal__content {
	ul {
		list-style: disc;
		padding-left: 2.4rem;
	}
}

hr {
	border: 0;
	height: 1px;
	background: rgba(0,0,0,0.3);
	margin: 2.4rem 0;
}

.cta {
	text-align: left;
	margin-bottom: -0.8rem;
	padding-top: 1.2rem;

	.button {
		margin: 0.8rem;
		margin-left: 0;
	}
}

footer {
	background: darken($navy, 10%);
	text-align: center;
	font-size: 1.3rem;
	padding: 1.6rem 0.8rem;
	color: white;
}
