#examples {

	.inner-wrapper {
		display: flex;
		flex-wrap: wrap;
	}

	.gallery-item {
		display: block;
		background: transparent;
		border-top: 0;
		border-bottom: 0;
		position: relative;
		overflow: hidden;
		width: 100%;

		&--02, &--03 {
			display: none;
		}

		@media only screen and (min-width:570px) {
			width: 50%;
			&--02 {
				display: block;
			}
		}

		@media only screen and (min-width:719px) {
			&--03 {
				display: block;
			}
		}

		@media only screen and (min-width:1440px) {
			width: 25%;
		}

		&.hidden {
			display: none;
		}

		img {
			width: 100%;
			display: block;
		}

		&__image {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 101%;
			height: 101%;
			background: white url(#) no-repeat center center;
			background-size: cover;
			transition: all 0.3s;
		}

		.overlay {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			z-index: 10;
			background: rgba(255,255,255,0.0);
			display: flex;
			justify-content: center;
			align-items: center;
			transition: background 0.3s;

			&__cta {
				border: 1px solid transparent;
				background: transparent;
				color: $navy;
				opacity: 0;
				transition: opacity 0.3s;
			}

			&:hover {
				background: rgba(255,255,255,0.75);

				.overlay {
					&__cta {
						opacity: 1;
					}
					&__copy {
						background: rgba(255,255,255,0);
					}
				}
			}

			&__copy {
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				background: rgba(255,255,255,0.85);
				padding: 1.6rem;
				color: $navy;
				transition: background, 0.3s;
				display: none;

				&--small-cta {
					display: block;
				}

				@media only screen and (min-width:570px) {
					display: block;

					&--small-cta {
						display: none;
					}
				}

			}

			&__title, &__subtitle {
				display: block;
				line-height: 1.5em;
			}

			&__title {
				font-family: $font-dosis;
				text-transform: uppercase;
				font-size: 1.5rem;
			}

			&__subtitle {
				font-size: 1.3rem;
			}

		}

		&.intro {
			background: white;
			padding: 4.8rem 1.6rem;
			width: 100%;

			@media only screen and (min-width:570px) {
				padding: 4.8rem;
			}

			@media only screen and (min-width:719px) {
				padding: 1.6rem;
				width: 50%;
			}

			@media only screen and (min-width:1440px) {
				width: 25%;
				background: transparent;
				padding-left: 0;
			}

		}

	}

}
