.social-discs {
	display: flex;
	align-items: flex-end;
	justify-content: center;

	.icon {
		display: inline-block;
		text-align: center;
		background: $positive-interactive url(#) no-repeat center center;
		color: white;
		font-weight: 600;
		width: 4.8rem;
		height: 4.8rem;
		border-radius: 5px;
		border: 2px solid white;
		overflow: hidden;
		transition: background-color, 0.3s;
		position: relative;

		&--twitter {
			background-image:url(/assets/twitter.svg);
		}
		&--linkedin {
			background-image:url(/assets/linkedin.svg);
		}
		&--houzz {
			background-image:url(/assets/houzz.svg);
		}
		&--email {
			background-image:url(/assets/email.svg);
		}

		&__copy {
			display: block;
			width: 4.6rem;
			position: absolute;
			text-align: center;
			top: 50%;
			margin-top: -32%;
		}

		&:hover {
			background-color: darken($positive-interactive, 10%);
		}

	}

}

.section {
	background: transparent;

	@media only screen and (min-width:1045px) {
		padding: 12.8rem 1.6rem;
		margin-top: 8.5rem;
	}

	.inner-wrapper {
		display: block;

		@media only screen and (min-width:1045px) {
			max-width: 130rem;
			display: flex;
		}
	}

	.copy {
		background: transparent;
		padding: 4.8rem 1.6rem;
		padding-bottom: 5.8rem;
		width: 100%;

		@media only screen and (min-width:570px) {
			padding: 4.8rem;
		}

		@media only screen and (min-width:1045px) {
			max-width: 50%;
			padding-top: 2.4rem;
			padding-right: 0;
		}


		h2 {
			padding-bottom: 0;
		}

		ul, ol {
			margin: 1em 0;
		}
	}

	.img {
		width: 100%;
		background: transparent url(#) no-repeat 20% center;
		background-size: cover;
		position: relative;
		padding-top: 56.25%;

		@media only screen and (min-width:1045px) {
			width: 50%;
			padding-top: 0;
		}

		.social-discs {
			display: block;
			position: absolute;
			right: 1.6rem;
			bottom: -3.3rem;

			@media only screen and (min-width:420px) {
				bottom: 1rem;

			}

			@media only screen and (min-width:570px) {
				bottom: 4rem;
				right: 4.8rem;
			}

			.icon {
				margin-left: 0.4rem;
			}

		}

	}

	h2 {
		font-family: $font-open-sans;
		font-weight: 400;
		font-size: 1.8rem;
	}

	@media only screen and (min-width:1045px) {
		&--flip {
			.copy {
				padding-left: 0;
				padding-right: 2.4rem;
			}

			.img {
				order: 2;
			}
		}
	}

	&--full {

		.inner-wrapper {
			max-width: 100%;
		}

	}

}
