.global-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: #fff;
	color: #777;
	box-shadow: 0 1px 3px 3px rgba(0,0,0,.1);
	z-index: 100;
	padding: 1.6rem 0.8rem;

	@media only screen and (min-width:740px) {
		padding: 1.6rem 1.6rem;
	}

	.global-wrapper {
		width: 100%;
		max-width: 150rem;
		margin: 0 auto;

		@media only screen and (min-width:740px) {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}

	}

}

.logo {
	font-family: $font-dosis;
	text-transform: uppercase;
	font-size: 2.4rem;
	text-align: center;
	background-size: auto 100%;
	color: $grey;
	display: block;
	height: 2.4rem;
	line-height: 2.4rem;

	@media only screen and (min-width:740px) {
		display: inline;
		height: 6rem;
		line-height: 6rem;
	}

	span {
		color: $navy;
	}
}

.global-nav {
	margin-top: -0.2rem;
	text-align: center;


	ul {
		display: none;

		@media only screen and (min-width:440px) {
			display: block;
		}

	}

	@media only screen and (min-width:740px) {
		margin-top: -0.2rem;
		text-align: left;
	}

	li {
		@extend %link-button-format;
		display: inline-block;
		padding: 0 0.8rem;
		padding-top: 1.2rem;

		@media only screen and (min-width:740px) {
			padding-top: 2rem;

			&:last-of-type {
				padding-right: 0;
			}
		}

		a {
			color: $grey;
			display: inline-block;
			border-bottom: 2px solid transparent;
			transition: all 0.3s;
			padding-bottom: 0.4rem;

			@media only screen and (min-width:740px) {
				margin-left: 1.6rem;
			}

			&:hover {
				color: $blue;
				border-bottom-color: $blue;
			}

			&.active {
				border-bottom-color: $blue;
				color: $grey;
				cursor: default;
			}
		}
	}

}

body.navOpen {
	overflow: hidden;

	@media only screen and (min-width:440px) {
		overflow: auto;
	}

	.global-header, .global-wrapper, footer {
		filter: blur(4px);
		@media only screen and (min-width:440px) {
			filter: blur(0);
		}
	}

	.mobile-nav {
		top: 0;
		bottom: 0;
		display: flex;
		opacity: 1;

		@media only screen and (min-width:440px) {
			display: none;
		}

	}
}

.mobileToggleNav {
	@extend %link-button-format;
	margin: 1.6rem auto 0.2rem auto;
	width: 30rem;
	display: block;

	@media only screen and (min-width:440px) {
		display: none;
	}
}

.mobile-nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	top: -100%;
	z-index: 1000;
	background: rgba(255,255,255,0.75);
	text-align: center;
	opacity: 0;
	transition: opacity 0.5s;

	ul {
		margin: auto;
	}

	li {
		@extend %link-button-format;
		a {
			display: block;
			width: 30rem;
			padding: 0.8rem 0;
			margin: 1.6rem 0;
			color: $blue;
		}
	}
}
